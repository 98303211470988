import { useLazyQuery } from '@apollo/client';
import { Card, Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import UserResume from './components/UserResume';
import { SHARED_RESUME, SHARED_RESUMES } from './graphql/Queries';

const EmployeeResume = () => {
  const {
    state: { clientId },
  } = useContext(AppContext);
  const history = useHistory();
  const [activeKey, setActiveKey] = useState();
  const [executeQuery, { data }] = useLazyQuery(SHARED_RESUMES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (!activeKey) {
        setActiveKey(res?.sharedResumes?.[0]?.id);
      }
    },
    onError: () => {},
  });

  const [executeResumeQuery] = useLazyQuery(SHARED_RESUME, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      executeQuery({
        variables: {
          input: {
            clientId,
          },
        },
      });
    },
    onError: () => {},
  });

  useEffect(() => {
    if (clientId) {
      executeQuery({
        variables: {
          input: {
            clientId,
          },
        },
      });
    } else {
      history?.push(`${ROUTES?.GUEST}${ROUTES?.LOGIN}`);
    }
  }, [clientId]);

  const resumes = data?.sharedResumes || [];

  const handleResumeSidebar = async (resumeId) => {
    setActiveKey(resumeId);
    await executeResumeQuery({
      variables: {
        input: {
          sharedUserResumeId: resumeId,
        },
      },
    });
  };

  const CustomTabBar = ({ resume }) => (
    <div className="resume-tab-pane-sidebar">
      {resume?.map((pane) => (
        <div
          key={pane?.key}
          onClick={() => handleResumeSidebar(pane?.id)}
          className={pane?.hasRead ? 'resume-read' : 'resume-unread'}
        >
          {pane?.resumeData?.basicDetails?.displayName ||
            pane?.resumeData?.basicDetails?.shortName}
          <br />
          {pane?.resumeData?.basicDetails?.jobTitle}
        </div>
      ))}
    </div>
  );

  return (
    <div className="employee-resume-container">
      <Card
        className="ant-body-scroll employee-resume-page"
        title="Below is the resume shared for your review by Logicwind"
      >
        <Tabs
          tabPosition="left"
          defaultActiveKey={activeKey}
          renderTabBar={(props) => <CustomTabBar {...props} resume={resumes} />}
        >
          <TabPane>
            <UserResume
              data={resumes?.find((resume) => resume?.id === activeKey)}
            />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default EmployeeResume;
