import {
  ArrowLeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { debounce, filter, flatMap, map } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import noImageIcon from '../../assets/images/noImageIcon.png';
import {
  BACKEND_DATE_FORMAT,
  COLORS,
  DEFAULT_DATE_FORMAT,
  GENDER,
  LEVEL,
  LIMIT,
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROLES,
  ROLE_KEYS,
  ROUTES_MODULES_KEY,
  STATUS,
  USER_SKILL_SET,
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import CommonSelect from '../../components/CommonSelect';
import LoaderComponent from '../../components/LoaderComponent';
import VerifyPermissions from '../../components/VerifyPermissions';
import { ROLE_ACCESS } from '../role/graphql/Queries';
import { ADMIN_UPDATE, CREATE_USER } from './graphql/Mutations';
import {
  ALL_TAGS,
  DEPARTMENTS,
  EMPLOYEE_DETAILS,
  SKILL_SET,
  USERS,
  USER_LEVEL,
} from './graphql/Queries';

const { Title } = Typography;
const { required, email } = formValidatorRules;

function ViewUser(props) {
  const {
    location: { state: propsState },
  } = props;

  const {
    state: { currentUser },
  } = useContext(AppContext);

  const { initialDateFilter, initialFilter, paginationFilter } =
    propsState || {};

  const pathName = initialDateFilter?.pathname;
  const { userId } = useParams();
  const [form] = Form?.useForm();
  const history = useHistory();
  const defaultSkip = 0;
  const defaultSortOrder = 'ASC';
  const [editableButton, setEditableButton] = useState(false);
  const [tags, setTags] = useState([]);
  const [skillSet, setSkillSet] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [search, setSearch] = useState('');
  const [tagSearch, setTagSearch] = useState('');
  const [languageSearch, setLanguageSearch] = useState('');
  const [toolsSearch, setToolsSearch] = useState('');
  const [serviceSearch, setServiceSearch] = useState('');
  const [otherSearch, setOtherSearch] = useState('');
  const [roleSearch, setRoleSearch] = useState('');
  const [departmentSearch, setDepartmentSearch] = useState('');
  const [userSearch, setUserSearch] = useState('');
  const [validationTriggered, setValidationTriggered] = useState(false);
  const isUpdatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.USERS,
    allowedPermissions: [PERMISSION_OBJECT?.update],
  });

  const inputRef = useRef(null);
  const editInputRef = useRef(null);
  const inputTagWidth =
    inputValue?.length > 80 ? 'expanding-tag-width' : 'default-tag-width';

  const handleRedirect = () => {
    if (pathName) {
      history?.push({
        pathname: pathName,
        state: {
          ...initialDateFilter,
        },
      });
    } else {
      history?.push({
        pathname: `${ROUTES_MODULES_KEY?.USERS}`,
        state: {
          ...initialFilter,
          ...paginationFilter,
        },
      });
    }
  };

  const [executeQuery, { data }] = useLazyQuery(EMPLOYEE_DETAILS, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [executeLevelQuery, { data: levelData, loading }] = useLazyQuery(
    USER_LEVEL,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [executeMutation] = useMutation(ADMIN_UPDATE, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      handleRedirect();
    },
    onError() {},
  });

  const [executeCreateUser] = useMutation(CREATE_USER, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      handleRedirect();
    },
    onError() {},
  });

  useEffect(() => {
    if (userId) {
      executeQuery({
        variables: {
          id: userId,
        },
      });
    }
  }, [userId]);

  useEffect(() => {
    executeLevelQuery();
  }, []);

  useEffect(() => {
    if (data) {
      const {
        integrations,
        firstName,
        lastName,
        billable,
        jobTitle,
        permissionRole,
        employeeNumber,
        internNumber,
        reportingManager,
        department,
        dateOfJoining,
        dateOfBirth,
        workEmail,
        personalEmail,
        mobileNo,
        tags: userTags,
        skills,
      } = data?.employeeDetails || {};
      form?.setFieldsValue({
        integrations,
        firstName,
        lastName,
        billable,
        jobTitle,
        roleId: permissionRole?.id,
        employeeNumber,
        internNumber,
        managerId: reportingManager?.displayName,
        departmentId: department?.name,
        dateOfJoining: dayjs(dateOfJoining),
        dateOfBirth: dayjs(dateOfBirth),
        workEmail,
        personalEmail,
        mobileNo,
        isActive: data?.employeeDetails?.isActive,
      });
      setTags([...userTags]);
      setSkillSet([...skills]);
    } else {
      form?.setFieldsValue({
        gender: GENDER?.[0]?.value,
        billable: false,
        jobTitle: ROLES.INTERN,
      });
    }
  }, [data]);

  const handleBack = () => {
    if (pathName) {
      history?.push({
        pathname: pathName,
        state: {
          ...initialDateFilter,
        },
      });
    } else {
      history?.push({
        pathname: `${ROUTES_MODULES_KEY?.USERS}`,
        state: {
          ...initialFilter,
          ...paginationFilter,
        },
      });
    }
  };

  const handleSave = async () => {
    form?.submit();
  };

  useEffect(() => {
    if (inputVisible) {
      inputRef?.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef?.current?.focus();
  }, [inputValue]);

  useEffect(() => {
    if (editInputRef?.current) {
      const inputElement = editInputRef?.current?.input;
      const textWidth = inputElement?.scrollWidth + 2;
      inputElement.style.width = `${Math?.max(20, textWidth)}px`;
    }
  }, [editInputValue, editInputIndex]);

  const handleClose = (removedTag) => {
    const newTags = tags?.filter((tag) => tag !== removedTag);
    setEditableButton(true);
    setTags(newTags);
  };

  const handleSkillTagClose = (skillTag, skillName) => {
    setEditableButton(true);
    setSkillSet((prevSkillSet) => {
      const updatedSkillSet = [...prevSkillSet];
      const skillIndex = updatedSkillSet?.findIndex(
        (skill) => skill?.skillName === skillName,
      );
      if (skillIndex !== -1) {
        const updatedSkill = { ...updatedSkillSet[skillIndex] };
        updatedSkill.skillValue = updatedSkill?.skillValue?.filter(
          (tag) => tag !== skillTag,
        );
        updatedSkillSet[skillIndex] = updatedSkill;
      }
      return updatedSkillSet;
    });
  };

  const showInput = () => {
    setInputVisible(true);
    inputRef?.current?.focus();
  };

  const handleEditInputChange = (e) => {
    const editInputValues = e?.target?.value;
    setEditInputValue(editInputValues?.toLowerCase());
  };
  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue('');
  };

  const handleTagChange = (selectedTags) => {
    setSearch('');
    setTagSearch('');
    setEditableButton(true);
    const duplicateTags = selectedTags?.filter((tag) => !tags?.includes(tag));
    setSelectedItems(duplicateTags);
  };

  const handleDepartmentSearch = debounce((value) => {
    setDepartmentSearch(value);
  }, 500);

  const handleDepartmentSelect = () => setDepartmentSearch('');

  const handleUserSearch = debounce((value) => {
    setUserSearch(value);
  }, 500);

  const handleUserSelect = () => setUserSearch('');

  const setLanguageDebounced = useCallback(
    debounce(setLanguageSearch, 500),
    [],
  );
  const setToolsDebounced = useCallback(debounce(setToolsSearch, 500), []);
  const setServiceDebounced = useCallback(debounce(setServiceSearch, 500), []);
  const setOtherDebounced = useCallback(debounce(setOtherSearch, 500), []);
  const handleLanguageSearch = (value) => {
    setLanguageDebounced(value);
  };
  const handleLanguageSelect = () => {
    setLanguageSearch('');
  };
  const handleToolsSearch = (value) => {
    setToolsDebounced(value);
  };
  const handleToolsSelect = () => {
    setToolsSearch('');
  };
  const handleServiceSearch = (value) => {
    setServiceDebounced(value);
  };
  const handleServiceSelect = () => {
    setServiceSearch('');
  };
  const handleOtherSearch = (value) => {
    setOtherDebounced(value);
  };
  const handleOtherSelect = () => {
    setOtherSearch('');
  };

  const debouncedUpdateValue = useCallback(
    debounce((newValue) => {
      setTagSearch(newValue?.toLowerCase());
    }, 500),
    [],
  );

  const updateSearchValue = (newValue) => {
    setSearch(newValue?.toLowerCase());
  };

  const handleTagSearch = (searchText) => {
    debouncedUpdateValue(searchText);
    updateSearchValue(searchText);
  };

  const renderMaxTag = (omittedValues) => {
    if (omittedValues?.length === 0) {
      return null;
    }
    return <span>+ {omittedValues?.length} more...</span>;
  };

  const handleRoleSearch = debounce((value) => {
    setRoleSearch(value?.toString());
  }, 500);

  const handleRoleSelect = () => setRoleSearch('');

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const onFinish = async (values) => {
    const initialValues = skillSet?.map((skill) => {
      const { skillName, skillValue } = skill;
      const updatedSkill = { ...skill };
      const matchingCategory = USER_SKILL_SET?.find(
        (category) => category?.value === skillName,
      );
      if (matchingCategory && skillValue) {
        const categoryKey = matchingCategory?.label;
        const newValue = values?.[categoryKey];
        updatedSkill.skillValue = Array?.isArray(skillValue)
          ? [...skillValue, ...(newValue || [])]
          : [newValue];
      }
      return updatedSkill;
    });

    USER_SKILL_SET?.forEach((skill) => {
      const skillIndex = initialValues?.findIndex(
        (initialSkill) => initialSkill?.skillName === skill.value,
      );
      if (skillIndex === -1 && values?.[skill?.label]) {
        initialValues?.push({
          skillName: skill.value,
          skillValue: values?.[skill?.label],
        });
      }
    });
    await form?.validateFields();
    const commonInput = {
      userId: data?.employeeDetails?.id,
      integrations: values?.integrations,
      billable: values?.billable,
      tags: selectedItems?.length > 0 ? [...tags, ...selectedItems] : [...tags],
      skillSets: initialValues || [],
      roleId:
        values?.roleId?.id ||
        values?.roleId ||
        data?.employeeDetails?.roleId ||
        null,
      isActive: values?.isActive,
    };

    const commonInternInput = {
      firstName: values?.firstName?.trim(),
      lastName: values?.lastName?.trim(),
      mobileNo: values?.mobileNo?.trim(),
      dateOfJoining: values?.dateOfJoining?.format(BACKEND_DATE_FORMAT),
      dateOfBirth: values?.dateOfBirth?.format(BACKEND_DATE_FORMAT),
      workEmail: values?.workEmail,
      personalEmail: values?.personalEmail,
      gender: values?.gender,
      internNumber: values?.internNumber,
      jobTitle: values?.jobTitle,
    };

    if (userId) {
      await executeMutation({
        ...(data?.employeeDetails?.isIntern
          ? {
              variables: {
                input: {
                  ...commonInput,
                  ...commonInternInput,
                  departmentId:
                    values?.departmentId ===
                    data?.employeeDetails?.department?.name
                      ? data?.employeeDetails?.department?.id
                      : values?.departmentId,
                  managerId:
                    values?.managerId ===
                    data?.employeeDetails?.reportingManager?.displayName
                      ? data?.employeeDetails?.reportingManager?.id
                      : values?.managerId,
                  isIntern: true,
                },
              },
            }
          : {
              variables: {
                input: {
                  ...commonInput,
                },
              },
            }),
      });
    } else {
      await executeCreateUser({
        variables: {
          input: {
            ...commonInternInput,
            departmentId: values?.departmentId,
            managerId: values?.managerId,
            roleId: values?.roleId || null,
            billable: values?.billable,
          },
        },
      });
    }
    setSelectedItems([]);
    setEditableButton(false);
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <Card
        size="small"
        className="ant-body-scroll"
        title={
          <div className="d-flex align-center">
            <Button
              type="text"
              shape="square"
              onClick={handleBack}
              icon={<ArrowLeftOutlined />}
            />
            {userId ? 'User Details' : 'Create User'}
          </div>
        }
        extra={
          <>
            <div className="users-sidebar">
              <div className="user-filter-right">
                <div className="user-filter">
                  <Button
                    type="primary"
                    onClick={handleSave}
                    disabled={!editableButton}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </>
        }
      >
        <div className="card-body-wrapper user-detail-page">
          <div className="card-padding">
            <Form
              name="control-ref"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onFieldsChange={() => setEditableButton(true)}
              layout="vertical"
              validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
            >
              {userId && (
                <>
                  <Row gutter={16}>
                    <Col>
                      <div className="profile-avatar">
                        <Avatar
                          size={{
                            xs: 24,
                            sm: 32,
                            md: 40,
                            lg: 64,
                            xl: 80,
                            xxl: 100,
                          }}
                          src={
                            data?.employeeDetails?.profileImage || noImageIcon
                          }
                        />
                        <div>
                          <Title level={4} className="d-flex align-center">
                            {data?.employeeDetails?.displayName}
                            <span>
                              ({data?.employeeDetails?.department?.name})
                            </span>
                          </Title>
                          <div className="job-title">
                            {data?.employeeDetails?.jobTitle}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Divider />
                </>
              )}
              <div className="user-details-heading">
                <Title level={4}>Personal Details</Title>
              </div>
              <Row gutter={[16, 16]}>
                {(data?.employeeDetails?.isIntern || !userId) && (
                  <>
                    <Col span={6}>
                      <Form.Item
                        name="firstName"
                        label="First Name"
                        rules={[
                          {
                            required: true,
                            message: 'Please Enter First Name!',
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input placeholder="Enter First Name" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name="lastName"
                        label="Last Name"
                        rules={[
                          {
                            required: true,
                            message: 'Please Enter Last Name!',
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input placeholder="Enter Last Name" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name="gender"
                        label="Gender"
                        rules={[
                          {
                            required: true,
                            message: 'Please Choose Gender!',
                          },
                        ]}
                      >
                        <Select options={GENDER} showSearch />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name="dateOfBirth"
                        label="Date Of Birth"
                        className="create-user-datepicker"
                        rules={[
                          {
                            required: true,
                            message: 'Please Add Date Of Birth!',
                          },
                        ]}
                      >
                        <DatePicker format={DEFAULT_DATE_FORMAT} />
                      </Form.Item>
                    </Col>
                  </>
                )}
                <Col span={6}>
                  <Form.Item
                    name="mobileNo"
                    label="Mobile Number"
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter Mobile Number!',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input
                      disabled={!data?.employeeDetails?.isIntern && userId}
                      placeholder="Enter Mobile Number"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="personalEmail"
                    label="Personal Email"
                    rules={[
                      {
                        required: data?.employeeDetails?.isIntern || !userId,
                        message: 'Please Enter Personal Email!',
                      },
                      email,
                    ]}
                  >
                    <Input
                      disabled={userId}
                      placeholder="Enter Personal Email"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="user-details-heading">
                <Title level={4}>Organization Details</Title>
              </div>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <Form.Item
                    name="managerId"
                    label="Reporting Manager"
                    rules={[
                      {
                        required: true,
                        message: 'Please Choose Manager Name!',
                      },
                    ]}
                  >
                    <CommonSelect
                      placeholder="Select User"
                      showSearch
                      query={USERS}
                      onSearch={handleUserSearch}
                      onChange={handleUserSelect}
                      responsePath="usersAdmin.users"
                      valuePath="id"
                      labelPath="displayName"
                      fetchPolicy="network-only"
                      variables={{
                        filters: {
                          searchStrings: userSearch,
                          levelIds: flatMap(
                            [LEVEL?.L1, LEVEL?.L2, LEVEL?.L3, LEVEL?.L4],
                            (levelName) =>
                              map(
                                filter(levelData?.levels?.levels, {
                                  name: levelName,
                                }),
                                'id',
                              ),
                          ),
                        },
                      }}
                      useEffectDeps={[userSearch]}
                      virtual={false}
                      disabled={!data?.employeeDetails?.isIntern && userId}
                    />
                  </Form.Item>
                </Col>
                {data?.employeeDetails?.isIntern || !userId ? (
                  <Col span={6}>
                    <Form.Item
                      name="internNumber"
                      label="Intern Number"
                      rules={[
                        {
                          required: true,
                          message: 'Please Enter Intern Number!',
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        disabled={data?.employeeDetails?.isIntern && userId}
                        placeholder="Enter Intern Number"
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  <Col span={6}>
                    <Form.Item
                      name="employeeNumber"
                      label="Employee Number"
                      rules={[
                        {
                          required: true,
                          message: 'Please Enter Employee Number!',
                        },
                      ]}
                    >
                      <Input
                        disabled={!data?.employeeDetails?.isIntern && userId}
                        placeholder="Enter Employee Number"
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col span={6}>
                  <Form.Item
                    name="dateOfJoining"
                    label="Date Of Joining"
                    className="create-user-datepicker"
                    rules={[
                      {
                        required: true,
                        message: 'Please Add Date Of Joining!',
                      },
                    ]}
                  >
                    <DatePicker
                      disabled={!data?.employeeDetails?.isIntern && userId}
                      format={DEFAULT_DATE_FORMAT}
                    />
                  </Form.Item>
                </Col>
                {(data?.employeeDetails?.isIntern || !userId) && (
                  <Col span={6}>
                    <Form.Item name="jobTitle" label="Job Title">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                )}
                <Col span={6}>
                  <Form.Item
                    name="workEmail"
                    label="Work Email"
                    rules={[
                      { required, message: 'Please Enter Work Email!' },
                      email,
                    ]}
                  >
                    <Input
                      disabled={userId}
                      placeholder="Enter Email Work Email"
                    />
                  </Form.Item>
                </Col>
                {(data?.employeeDetails?.isIntern || !userId) && (
                  <Col span={6}>
                    <Form.Item
                      name="departmentId"
                      label="Department"
                      rules={[
                        {
                          required: true,
                          message: 'Please Choose Department!',
                        },
                      ]}
                    >
                      <CommonSelect
                        placeholder="Select Department"
                        showSearch
                        query={DEPARTMENTS}
                        onSearch={handleDepartmentSearch}
                        onChange={handleDepartmentSelect}
                        variables={{ search: departmentSearch }}
                        useEffectDeps={[departmentSearch]}
                        responsePath="departments.departments"
                        valuePath="id"
                        labelPath="name"
                        fetchPolicy="network-only"
                        value={initialFilter?.departmentId}
                        virtual={false}
                      />
                    </Form.Item>
                  </Col>
                )}
                {[ROLE_KEYS?.SUPER_ADMIN, ROLE_KEYS?.ADMIN]?.includes(
                  currentUser?.role,
                ) && (
                  <Col span={6}>
                    <Form.Item name="roleId" label="Assign Role">
                      <CommonSelect
                        className="role-select-tags"
                        placeholder="Assign Role"
                        showSearch
                        allowClear
                        customOptions={[
                          {
                            id: data?.employeeDetails?.permissionRole?.id,
                            name: data?.employeeDetails?.permissionRole?.name,
                          },
                        ]}
                        query={ROLE_ACCESS}
                        onSearch={handleRoleSearch}
                        onChange={handleRoleSelect}
                        responsePath="rolePermissions.data"
                        valuePath="id"
                        labelPath="name"
                        fetchPolicy="network-only"
                        variables={{
                          filters: {
                            skip: 0,
                            limit: LIMIT,
                            searchString: roleSearch,
                          },
                        }}
                        useEffectDeps={[roleSearch]}
                        value={roleSearch}
                        virtual={false}
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col span={6}>
                  <Form.Item name="billable" label="Mark as Billable Resource?">
                    <Radio.Group>
                      <Radio disabled={!isUpdatePermission} value>
                        Yes
                      </Radio>
                      <Radio disabled={!isUpdatePermission} value={false}>
                        No
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                {userId && (
                  <Col span={6}>
                    <Form.Item
                      name="isActive"
                      label="Status"
                      valuePropName="checked"
                    >
                      <Switch
                        checkedChildren={STATUS[0].text}
                        unCheckedChildren={STATUS[1].text}
                        disabled={!isUpdatePermission}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              {userId && (
                <>
                  <div className="user-details-heading">
                    <Title level={4}>Others Details</Title>
                  </div>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Space direction="vertical">
                        <Form.Item
                          className="external-reference-form-item"
                          label="External Reference"
                        />
                        <Form.List name="integrations">
                          {(fields, { add, remove }) => (
                            <>
                              {fields?.map((field, index) => (
                                <Space key={field?.key} align="baseline">
                                  <Form.Item
                                    key={field?.index}
                                    name={[field?.name, 'referenceName']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please Add Reference Name!',
                                      },
                                    ]}
                                  >
                                    <Input
                                      disabled={!isUpdatePermission}
                                      placeholder={`Reference Name ${
                                        index + 1
                                      }:`}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    key={field?.index}
                                    name={[field?.name, 'referenceId']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please Add Reference Id!',
                                      },
                                    ]}
                                  >
                                    <Input
                                      disabled={!isUpdatePermission}
                                      placeholder={`Reference Id ${index + 1}:`}
                                    />
                                  </Form.Item>
                                  {isUpdatePermission && (
                                    <MinusCircleOutlined
                                      onClick={() => remove(field?.name)}
                                    />
                                  )}
                                </Space>
                              ))}
                              {isUpdatePermission && (
                                <Form.Item>
                                  <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    icon={<PlusOutlined />}
                                  >
                                    Add field
                                  </Button>
                                </Form.Item>
                              )}
                            </>
                          )}
                        </Form.List>
                      </Space>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="tags" label="Tags">
                        <Space size={[0, 8]} wrap>
                          {tags?.map((tag, index) => {
                            if (editInputIndex === index) {
                              return (
                                <Input
                                  ref={editInputRef}
                                  key={tag}
                                  size="small"
                                  className={`${inputTagWidth} mr-8`}
                                  value={editInputValue}
                                  onChange={handleEditInputChange}
                                  onBlur={handleEditInputConfirm}
                                  onPressEnter={handleEditInputConfirm}
                                  autoFocus
                                />
                              );
                            }
                            const tagElem = (
                              <Tag
                                color={
                                  COLORS[
                                    Math?.floor(Math?.random() * COLORS?.length)
                                  ]
                                }
                                key={tag}
                                closeIcon={isUpdatePermission}
                                onClose={() => handleClose(tag)}
                                onDoubleClick={
                                  !isUpdatePermission
                                    ? undefined
                                    : (e) => {
                                        e?.preventDefault();
                                        setEditInputIndex(index);
                                        setEditInputValue(tag);
                                      }
                                }
                              >
                                <span>{tag}</span>
                              </Tag>
                            );
                            return tagElem;
                          })}
                          {inputVisible && (
                            <CommonSelect
                              getPopupContainer={(trigger) =>
                                trigger?.parentNode
                              }
                              inputRef={inputRef}
                              mode="tags"
                              placeholder="Search Tags"
                              className="user-tags"
                              defaultOpen
                              query={ALL_TAGS}
                              onChange={handleTagChange}
                              onSearch={(searchText) => {
                                handleTagSearch(searchText);
                              }}
                              variables={{
                                searchString: tagSearch,
                                filters: {
                                  tagsToExclude: tags,
                                },
                              }}
                              useEffectDeps={[tagSearch]}
                              responsePath="searchTags.tags"
                              valuePath="name"
                              labelPath="name"
                              fetchPolicy="network-only"
                              searchValue={search}
                              virtual={false}
                            />
                          )}
                          {isUpdatePermission && !inputVisible && (
                            <Tag onClick={showInput} className="add-new-tag">
                              <PlusOutlined /> New Tag
                            </Tag>
                          )}
                        </Space>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <>
                        <Form.Item
                          label={USER_SKILL_SET?.[0]?.value}
                          name={USER_SKILL_SET?.[0]?.label}
                        >
                          <CommonSelect
                            mode="multiple"
                            placeholder="Select skills to be added"
                            query={SKILL_SET}
                            variables={{
                              filters: {
                                skip: defaultSkip,
                                limit: LIMIT,
                                userId,
                                searchString: languageSearch,
                                type: USER_SKILL_SET?.[0]?.value,
                                sortOrder: defaultSortOrder,
                              },
                            }}
                            showSearch
                            onSearch={handleLanguageSearch}
                            onChange={handleLanguageSelect}
                            useEffectDeps={[languageSearch]}
                            responsePath="skills.skills"
                            valuePath="name"
                            labelPath="name"
                            fetchPolicy="network-only"
                            virtual={false}
                            maxTagCount={3}
                            maxTagPlaceholder={renderMaxTag}
                            disabled={!isUpdatePermission}
                          />
                        </Form.Item>
                        <div className="user-tag-listing">
                          {(
                            skillSet?.find(
                              (skill) =>
                                skill?.skillName === USER_SKILL_SET?.[0]?.value,
                            )?.skillValue || []
                          )?.map((skillTag) => (
                            <Tag
                              color={
                                COLORS[
                                  Math?.floor(Math?.random() * COLORS?.length)
                                ]
                              }
                              key={skillTag}
                              closeIcon={isUpdatePermission}
                              onClose={() =>
                                handleSkillTagClose(
                                  skillTag,
                                  USER_SKILL_SET?.[0]?.value,
                                )
                              }
                            >
                              <span>{skillTag}</span>
                            </Tag>
                          ))}
                        </div>
                      </>
                    </Col>
                    <Col span={6}>
                      <>
                        <Form.Item
                          label={USER_SKILL_SET?.[1]?.value}
                          name={USER_SKILL_SET?.[1]?.label}
                        >
                          <CommonSelect
                            mode="multiple"
                            placeholder="Select skills to be added"
                            query={SKILL_SET}
                            variables={{
                              filters: {
                                skip: defaultSkip,
                                limit: LIMIT,
                                userId,
                                searchString: toolsSearch,
                                type: USER_SKILL_SET?.[1]?.value,
                                sortOrder: defaultSortOrder,
                              },
                            }}
                            showSearch
                            onSearch={handleToolsSearch}
                            onChange={handleToolsSelect}
                            useEffectDeps={[toolsSearch]}
                            responsePath="skills.skills"
                            valuePath="name"
                            labelPath="name"
                            fetchPolicy="network-only"
                            virtual={false}
                            maxTagCount={3}
                            maxTagPlaceholder={renderMaxTag}
                            disabled={!isUpdatePermission}
                          />
                        </Form.Item>
                        <div className="user-tag-listing">
                          {(
                            skillSet?.find(
                              (skill) =>
                                skill?.skillName === USER_SKILL_SET?.[1]?.value,
                            )?.skillValue || []
                          )?.map((skillTag) => (
                            <Tag
                              color={
                                COLORS[
                                  Math?.floor(Math?.random() * COLORS?.length)
                                ]
                              }
                              key={skillTag}
                              closeIcon={isUpdatePermission}
                              onClose={() =>
                                handleSkillTagClose(
                                  skillTag,
                                  USER_SKILL_SET?.[1]?.value,
                                )
                              }
                            >
                              <span>{skillTag}</span>
                            </Tag>
                          ))}
                        </div>
                      </>
                    </Col>
                    <Col span={6}>
                      <>
                        <Form.Item
                          label={USER_SKILL_SET?.[2]?.value}
                          name={USER_SKILL_SET?.[2]?.label}
                        >
                          <CommonSelect
                            mode="multiple"
                            placeholder="Select skills to be added"
                            query={SKILL_SET}
                            variables={{
                              filters: {
                                skip: defaultSkip,
                                limit: LIMIT,
                                userId,
                                searchString: serviceSearch,
                                type: USER_SKILL_SET?.[2]?.value,
                                sortOrder: defaultSortOrder,
                              },
                            }}
                            showSearch
                            onSearch={handleServiceSearch}
                            onChange={handleServiceSelect}
                            useEffectDeps={[serviceSearch]}
                            responsePath="skills.skills"
                            valuePath="name"
                            labelPath="name"
                            fetchPolicy="network-only"
                            virtual={false}
                            maxTagCount={3}
                            maxTagPlaceholder={renderMaxTag}
                            disabled={!isUpdatePermission}
                          />
                        </Form.Item>
                        <div className="user-tag-listing">
                          {(
                            skillSet?.find(
                              (skill) =>
                                skill?.skillName === USER_SKILL_SET?.[2]?.value,
                            )?.skillValue || []
                          )?.map((skillTag) => (
                            <Tag
                              color={
                                COLORS[
                                  Math?.floor(Math?.random() * COLORS?.length)
                                ]
                              }
                              key={skillTag}
                              closeIcon={isUpdatePermission}
                              onClose={() =>
                                handleSkillTagClose(
                                  skillTag,
                                  USER_SKILL_SET?.[2]?.value,
                                )
                              }
                            >
                              <span>{skillTag}</span>
                            </Tag>
                          ))}
                        </div>
                      </>
                    </Col>
                    <Col span={6}>
                      <>
                        <Form.Item
                          label={USER_SKILL_SET?.[3]?.value}
                          name={USER_SKILL_SET?.[3]?.label}
                        >
                          <CommonSelect
                            mode="multiple"
                            placeholder="Select skills to be added"
                            query={SKILL_SET}
                            variables={{
                              filters: {
                                skip: defaultSkip,
                                limit: LIMIT,
                                userId,
                                searchString: otherSearch,
                                type: USER_SKILL_SET?.[3]?.value,
                                sortOrder: defaultSortOrder,
                              },
                            }}
                            showSearch
                            onSearch={handleOtherSearch}
                            onChange={handleOtherSelect}
                            useEffectDeps={[otherSearch]}
                            responsePath="skills.skills"
                            valuePath="name"
                            labelPath="name"
                            fetchPolicy="network-only"
                            virtual={false}
                            maxTagCount={3}
                            maxTagPlaceholder={renderMaxTag}
                            disabled={!isUpdatePermission}
                          />
                        </Form.Item>
                        <div className="user-tag-listing">
                          {(
                            skillSet?.find(
                              (skill) =>
                                skill?.skillName === USER_SKILL_SET?.[3]?.value,
                            )?.skillValue || []
                          )?.map((skillTag) => (
                            <Tag
                              color={
                                COLORS[
                                  Math?.floor(Math?.random() * COLORS?.length)
                                ]
                              }
                              key={skillTag}
                              closeIcon={isUpdatePermission}
                              onClose={() =>
                                handleSkillTagClose(
                                  skillTag,
                                  USER_SKILL_SET?.[3]?.value,
                                )
                              }
                            >
                              <span>{skillTag}</span>
                            </Tag>
                          ))}
                        </div>
                      </>
                    </Col>
                  </Row>
                </>
              )}
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
}

export default ViewUser;
