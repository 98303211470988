import { gql } from '@apollo/client';

export const EMPLOYEE_RESUME = gql`
  query EmployeeResume($input: EmployeeResumeInput!) {
    employeeResume(input: $input) {
      basicDetails {
        firstName
        lastName
        middleName
        displayName
        profileImage
        jobTitle
        skills
        secondaryJobTitle
        logicwindExperience
        employeeNumber
      }
      totalExperience
      educationDetails {
        id
        cgpa
        branch
        degree
        university
        customFields
        yearOfJoining
        yearOfCompletion
      }
      experienceDetails {
        id
        jobTitle
        location
        companyName
        description
        customFields
        dateOfJoining
        dateOfRelieving
      }
      professionalSummary
      projectDetails {
        id
        name
        description
      }
    }
  }
`;

export const SHARED_RESUMES = gql`
  query SharedResumes($input: SharedResumesInput!) {
    sharedResumes(input: $input) {
      id
      sharedUserResumeId
      sharedTo
      sharedBy
      shortenName
      hideProfilePicture
      sharedResumeExpiryDate
      resumeData {
        basicDetails {
          employeeNumber
          displayName
          firstName
          jobTitle
          lastName
          middleName
          profileImage
          secondaryJobTitle
          skills
          shortName
          joiningDate
          logicwindExperience
        }
        totalExperience
        educationDetails {
          branch
          cgpa
          customFields
          degree
          id
          university
          yearOfCompletion
          yearOfJoining
        }
        experienceDetails {
          id
          jobTitle
          location
          companyName
          description
          customFields
          dateOfJoining
          dateOfRelieving
        }
        professionalSummary
        projectDetails {
          id
          name
          description
        }
      }
      hasRead
    }
  }
`;

export const SHARED_RESUME = gql`
  query SharedResume($input: SharedResumeByIdInput!) {
    sharedResume(input: $input) {
      id
      sharedUserResumeId
      sharedTo
      sharedBy
      shortenName
      hideProfilePicture
      sharedResumeExpiryDate
      resumeData {
        basicDetails {
          employeeNumber
          displayName
          firstName
          jobTitle
          lastName
          middleName
          profileImage
          secondaryJobTitle
          skills
          shortName
          joiningDate
          logicwindExperience
        }
        totalExperience
        educationDetails {
          branch
          cgpa
          customFields
          degree
          id
          university
          yearOfCompletion
          yearOfJoining
        }
        experienceDetails {
          id
          jobTitle
          location
          companyName
          description
          customFields
          dateOfJoining
          dateOfRelieving
        }
        professionalSummary
        projectDetails {
          id
          name
          description
        }
      }
      hasRead
    }
  }
`;
